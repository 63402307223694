<template>
  <AppPageCard>
    <template #card-title>
      {{ $t("LEAVE.ENTITLE") }}
    </template>

    <template #card-toolbar>
      <div
        class="btn btn-icon btn-light btn-hover-primary mr-3"
        v-on:click="refresh"
        v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
      >
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </div>
    </template>

    <AppBgLightAlert>
      <span class="font-weight-bold font-size-base d-none d-md-inline mr-1">
        {{ `${$t("EMPLOYEE.EMP")}:` }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ employee.name }}
      </span>
    </AppBgLightAlert>

    <b-table
      v-if="!isBusy"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      ref="leave-entitlement-page-dt"
      id="leave-entitlement-page-dt"
      table-class="table-head-custom table-vertical-center mt-1"
      responsive
      show-empty
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showLeaveEntitlement(data.item)"
            v-b-tooltip.hover="$t('LEAVE.HOVER.EDITENT')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div v-if="isBusy" class="d-flex my-8">
      <b-skeleton-table
        :rows="5"
        :columns="5"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table>
    </div>

    <div class="d-flex justify-content-end" v-if="items.length && !isBusy">
      <b-pagination
        pills
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="leave-entitlement-page-dt"
        @change="handlePageChange"
        size="lg"
      ></b-pagination>
    </div>
    <LeavePageEntitlementModal></LeavePageEntitlementModal>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import LeavePageEntitlementModal from "@/modules/company/components/leave/entitlement/employee/LeaveEmployeeEntitlementPageModal";
import AppBgLightAlert from "@/modules/core/components/utility/info/AppBgLightAlert";

export default {
  name: "LeavePageEntitlementTable",
  props: ["reload"],
  components: {
    AppBgLightAlert,
    AppPageCard,
    LeavePageEntitlementModal,
    AppLoader,
  },
  beforeMount() {
    this.$store.dispatch("assignSelectedEntitlementForEmployee", {
      id: this.$route.query.id,
      name: this.$route.query.name,
    });
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("ENTITLEMENT.TYPE") },
        { key: "days", label: this.$t("ENTITLEMENT.DAYS") },
        { key: "balance", label: this.$t("ENTITLEMENT.BALANCE") },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
      isBusy: false,
    };
  },
  methods: {
    /**
     * Fetch list,
     */
    fetch() {
      this.isBusy = true;

      const id = this.$route.query.id;
      this.$store
        .dispatch("fetchCompanyLeaveEntitlementList", id)
        .catch(() =>
          this.$bvModal.msgBoxOk("Not OK!", {
            centered: true,
          })
        )
        .finally(() => (this.isBusy = false));
    },

    /**
     * Refresh the list.
     */
    refresh() {
      this.fetch();
    },

    /**
     * Change page event
     *
     * @param value
     */
    handlePageChange(value) {
      console.log(value);
      this.$store.commit("setCompanyLeaveEntitlementListPage", value);
      this.fetch();
    },

    /**
     * Show selected entitlement.
     *
     * @param item
     */
    showLeaveEntitlement(item) {
      console.log("Selected Leave ", item);
      this.$store.dispatch("assignEmployeeToLeaveEntitlement", {
        id: item.id,
        employee_id: this.employee.id,
      });
      console.log("Fetching Leave Details");
      this.$store.dispatch("fetchSingleCompanyLeave", item.leave_id);
      this.$store.dispatch("fetchEntitlementForm", item.id);
      this.$store.dispatch("assignEntitlementFormIsSaved", false);
      this.$bvModal.show("leave-page-entitlement-modal");
    },
  },

  computed: {
    ...mapGetters({
      items: "getCompanyLeaveEntitlement",
      employee: "getSelectedEntitlementForEmployee",
      pagination: "getCompanyLeaveEntitlementPagination",
      isSaved: "getAssignEntitlementFormIsSaved",
    }),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.fetch();
    },
    isSaved(newState) {
      // If Form is Saved, close Modal and load Table
      if (newState) {
        this.$bvModal.msgBoxOk("Successfully updated Leave", {
          centered: true,
        });
        this.fetch();
      }
    },
  },
};
</script>

<style scoped></style>
